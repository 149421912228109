import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/chris/visioncortex.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SquareLogo } from './components/SquareLogo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <SquareLogo mdxType="SquareLogo" />
    <h1 {...{
      "id": "vision-cortex---semantic-computer-vision"
    }}>{`Vision Cortex - Semantic Computer Vision`}</h1>
    <p>{`The goal of Semantic Computer Vision is to allow computers to understand the content of images and graphics as intended and perceived by humans, and construct a high level representation of such information.`}</p>
    <p>{`This technology can be embodied in different applications:`}</p>
    <h2 {...{
      "id": "symbolic-barcode"
    }}>{`Symbolic Barcode`}</h2>
    <img style={{
      width: 100 + '%'
    }} src={'public/images/symcode-banner.jpg'} />
    <p>{`We developed `}
      <a {...{
        "href": "/symcode-docs",
        "parentName": "p"
      }}>{`SymCode`}</a>
      {`, a 2D barcode designed to be both human-readable and machine-readable.`}</p>
    <h2 {...{
      "id": "image-simplification"
    }}>{`Image Simplification`}</h2>
    <img style={{
      width: 100 + '%'
    }} src={'public/images/visionmagic-preview.png'} />
    <p>{`We developed `}
      <a {...{
        "href": "/impression-docs",
        "parentName": "p"
      }}>{`Impression`}</a>
      {`, a family of algorithms for image simplification and segmentation. It allows us to control the amount of visual information in an image in a quantitative manner.`}</p>
    <h2 {...{
      "id": "image-vectorization"
    }}>{`Image Vectorization`}</h2>
    <img style={{
      width: 100 + '%'
    }} src={'public/images/vtracer-demo.svg'} />
    <p>{`We developed `}
      <a {...{
        "href": "/vtracer-docs",
        "parentName": "p"
      }}>{`VTracer`}</a>
      {`, a utility to convert raster images (like jpg & png) into vector graphics (svg). Our graphics pipeline is able to process high resolution scans and photographs and trace the content to output compact vector files.`}</p>
    <h2 {...{
      "id": "optical-character-recognition"
    }}>{`Optical Character Recognition`}</h2>
    <img style={{
      width: 100 + '%'
    }} src={'public/images/forest-layering.svg'} />
    <p>{`We are developing a new Optical Character Recognition (OCR) engine from the ground up specifically for pictorial languages like Chinese, Korean and Japanese (CKJ) to encompass wider character sets and font variations.`}</p>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      